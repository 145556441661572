@font-face {
font-family: '__SFProDisplayLight_903cb9';
src: url(/_next/static/media/ed6a4e3c70d6c0a2-s.p.woff) format('woff');
font-display: swap;
font-weight: 100 900;
}@font-face {font-family: '__SFProDisplayLight_Fallback_903cb9';src: local("Arial");ascent-override: 103.98%;descent-override: 26.34%;line-gap-override: 0.00%;size-adjust: 91.57%
}.__className_903cb9 {font-family: '__SFProDisplayLight_903cb9', '__SFProDisplayLight_Fallback_903cb9'
}.__variable_903cb9 {--font-sf-pro-display-light-sans: '__SFProDisplayLight_903cb9', '__SFProDisplayLight_Fallback_903cb9'
}

@font-face {
font-family: '__SFProDisplayLightItalic_afd73e';
src: url(/_next/static/media/ee48fcea64050257-s.p.woff) format('woff');
font-display: swap;
font-weight: 100 900;
}@font-face {font-family: '__SFProDisplayLightItalic_Fallback_afd73e';src: local("Arial");ascent-override: 102.34%;descent-override: 25.93%;line-gap-override: 0.00%;size-adjust: 93.03%
}.__className_afd73e {font-family: '__SFProDisplayLightItalic_afd73e', '__SFProDisplayLightItalic_Fallback_afd73e'
}.__variable_afd73e {--font-sf-pro-display-light-italics-sans: '__SFProDisplayLightItalic_afd73e', '__SFProDisplayLightItalic_Fallback_afd73e'
}

@font-face {
font-family: '__SFProDisplayRegular_dd301e';
src: url(/_next/static/media/4e99cbc18025d610-s.p.woff) format('woff');
font-display: swap;
font-weight: 100 900;
}@font-face {font-family: '__SFProDisplayRegular_Fallback_dd301e';src: local("Arial");ascent-override: 101.50%;descent-override: 25.71%;line-gap-override: 0.00%;size-adjust: 93.80%
}.__className_dd301e {font-family: '__SFProDisplayRegular_dd301e', '__SFProDisplayRegular_Fallback_dd301e'
}.__variable_dd301e {--SF-Pro-Display-Regular-sans: '__SFProDisplayRegular_dd301e', '__SFProDisplayRegular_Fallback_dd301e'
}

@font-face {
font-family: '__SFProDisplayRegularItalic_953e6c';
src: url(/_next/static/media/b3a8b6435c06c5c4-s.p.woff) format('woff');
font-display: swap;
font-weight: 100 900;
}@font-face {font-family: '__SFProDisplayRegularItalic_Fallback_953e6c';src: local("Arial");ascent-override: 99.81%;descent-override: 25.29%;line-gap-override: 0.00%;size-adjust: 95.39%
}.__className_953e6c {font-family: '__SFProDisplayRegularItalic_953e6c', '__SFProDisplayRegularItalic_Fallback_953e6c'
}.__variable_953e6c {--SF-Pro-Display-RegularItalic-sans: '__SFProDisplayRegularItalic_953e6c', '__SFProDisplayRegularItalic_Fallback_953e6c'
}

